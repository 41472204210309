import React from 'react';
import PropTypes from 'prop-types';

import 'styles/components/button.css';

export default function Button({ text, onClick }) {
  return (
    <button type="button" className="button" onClick={onClick}>{text}</button>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
