import React from 'react';
import PropTypes from 'prop-types';

import 'styles/components/title.css';

export default function Title({ text }) {
  if (!text) {
    return null;
  }

  return (
    <h1 className="title">{text}</h1>
  );
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
};
