import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { TimetableApi, CategoryApi } from 'api';
import {
  Loading,
  EmptyList,
  Title,
  ProductList,
  Layout,
  TgMain,
} from 'components';
import { useSearchParams } from 'react-router-dom';

export default function FutureProductsPage() {
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [timetables, setTimetables] = useState([]);
  const [categories, setCategories] = useState(null);
  const [error, setError] = useState(null);

  const getTimetable = async (date) => {
    setIsLoading(true);

    try {
      const timetablesData = await TimetableApi.listByDate(date);
      const categoriesData = await CategoryApi.listCategories();

      setTimetables(timetablesData);
      setCategories(categoriesData);
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  const onContinue = () => {
    window.Telegram.WebApp.close();
  };

  useEffect(() => {
    getTimetable(searchParams.get('date'));
  }, [searchParams]);

  if (isLoading) {
    return <Loading text="Загрузка..." />;
  }

  if (error) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  if (timetables.length === 0) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  const timetable = timetables[0];

  if (!timetable || !timetable.menu || !timetable.menu.products) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  const date = moment(timetable.sheduledAt).format('dddd, DD MMMM');
  const title = date.charAt(0).toUpperCase() + date.slice(1);

  return (
    <Layout>
      <TgMain text="Напомнить" onClick={onContinue} />
      <Title text={title} />
      <ProductList
        categories={categories || []}
        products={timetable.menu.products || []}
        availablities={timetable.availableQuantities}
      />
    </Layout>
  );
}
