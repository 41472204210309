import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const TgMain = React.memo(({ text, onClick }) => {
  const tgApp = window.Telegram.WebApp;
  // const tgView = window.Telegram.WebView;

  // const event = 'web_app_setup_main_button';

  useEffect(() => {
    // tgView.postEvent(event, false, {
    //   is_visible: true,
    //   textColor: '#ffffff',
    //   color: '#ec2626',
    //   text,
    // });
    tgApp.MainButton.setText(text);
    tgApp.MainButton.show();
    tgApp.MainButton.enable();
    tgApp.MainButton.onClick(onClick);
    // tgView.onEvent('webview:mainButtonClicked', onClick);

    return () => {
      // tgApp.MainButton.hide();
      // tgView.postEvent(event, false, { is_visible: false });
      tgApp.MainButton.offClick(onClick);
      // tgView.offEvent('webview:mainButtonClicked', onClick);
    };
  });

  return null;
});

// export default function TgMain({ text, onClick }) {
//   const tgApp = window.Telegram.WebApp;
//   // const tgView = window.Telegram.WebView;

//   // const event = 'web_app_setup_main_button';

//   useEffect(() => {
//     // tgView.postEvent(event, false, {
//     //   is_visible: true,
//     //   textColor: '#ffffff',
//     //   color: '#ec2626',
//     //   text,
//     // });
//     tgApp.MainButton.setText(text);
//     tgApp.MainButton.show();
//     tgApp.MainButton.enable();
//     tgApp.MainButton.onClick(onClick);
//     // tgView.onEvent('webview:mainButtonClicked', onClick);

//     return () => {
//       tgApp.MainButton.hide();
//       // tgView.postEvent(event, false, { is_visible: false });
//       tgApp.MainButton.offClick(onClick);
//       // tgView.offEvent('webview:mainButtonClicked', onClick);
//     };
//   });

//   return null;
// }

TgMain.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TgMain;
