/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { DeliveryPeriodType } from 'types';
import Choices from './choices';
import Message from './message';

export default function OrderForm({
  periods = [], pickupText = '', data = {}, onChange,
}) {
  const periodItems = periods.map((d) => ({ value: d.id.toString(), label: d.title }));
  // periodItems.push({ label: 'Самовывоз', value: '' });
  // При раскомментировании вывести текст ниже, где true === false

  return (
    <form className="form">
      <label className="form__field" htmlFor="comment">
        <span className="form__label">Комментарий</span>
        <textarea
          rows={5}
          id="comment"
          className="form__control"
          placeholder="Оставьте комментарий к заказу (приборы, острота, точное время, нужно ли перезвонить...)."
          value={data.comment || ''}
          onChange={(e) => onChange('comment', e.target.value)}
        />
      </label>

      <div className="form__field">
        <span className="form__label">Выберите удобное время доставки</span>
        <Choices
          items={periodItems}
          value={data.deliveryPeriod || ''}
          onChange={(e) => onChange('deliveryPeriod', e.target.value)}
        />
        {!data.deliveryPeriod && true === false && (
          <div className="form__message">
            <Message text={pickupText} />
          </div>
        )}
      </div>

      <label className="form__field" htmlFor="first-name">
        <span className="form__label">Имя</span>
        <input
          type="text"
          rows={5}
          id="first-name"
          className="form__control"
          placeholder="Укажите Ваше имя"
          value={data.firstName || ''}
          onChange={(e) => onChange('firstName', e.target.value)}
          required
        />
      </label>

      <label className="form__field" htmlFor="first-name">
        <span className="form__label">Фамилия</span>
        <input
          type="text"
          id="first-name"
          className="form__control"
          placeholder="Укажите Вашу фамилию"
          value={data.lastName || ''}
          onChange={(e) => onChange('lastName', e.target.value)}
          required
        />
      </label>

      <label className="form__field" htmlFor="first-name">
        <span className="form__label">E-mail</span>
        <input
          type="email"
          id="email"
          className="form__control"
          placeholder="Укажите Ваш e-mail"
          value={data.email || ''}
          onChange={(e) => onChange('email', e.target.value)}
          required
        />
      </label>

      <label className="form__field" htmlFor="phone">
        <span className="form__label">Номер телефона</span>
        <InputMask
          id="phone"
          className="form__control"
          placeholder="Укажите Ваш номер телефона"
          mask="+79999999999"
          value={data.phone || ''}
          onChange={(e) => onChange('phone', e.target.value)}
        />
      </label>

      <label className="form__field" htmlFor="deliveryAddress">
        <span className="form__label">Адрес</span>
        <input
          type="text"
          id="deliveryAddress"
          className="form__control"
          placeholder="Укажите Ваш адрес"
          value={data.deliveryAddress || ''}
          onChange={(e) => onChange('deliveryAddress', e.target.value)}
          required
        />
      </label>

      <label className="form__field" htmlFor="agreement">
        <div className="checkbox">
          <input
            type="checkbox"
            id="agreement"
            className="checkbox__input"
            value="1"
            checked={data.agreement}
            onChange={() => onChange('agreement', !data.agreement)}
            required
          />
          <span className="checkbox__label">
            Я согласен на обработку персональных данных
          </span>
        </div>
      </label>
    </form>
  );
}

OrderForm.propTypes = {
  periods: PropTypes.arrayOf(DeliveryPeriodType).isRequired,
  pickupText: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};
