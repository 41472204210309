import axios from 'axios';

const tgData = window.Telegram.WebApp.initDataUnsafe;
const tgUserId = Object.entries(tgData).length > 0 ? tgData.user.id : 852489475;

const api = axios.create({
  withCredentials: false,
  // baseURL: 'https://roscomtech-kinza-backend.dev.local/webapp',
  baseURL: 'https://kinzapetrushka.ru/webapp',
  headers: {
    'X-TG-USER-ID': tgUserId,
  },
});

// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    // console.error(error);
  }

  return Promise.reject(error);
};

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => errorHandler(error));

// eslint-disable-next-line import/prefer-default-export
export { api };
