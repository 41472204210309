import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { TimetableApi, CategoryApi } from 'api';
import {
  Loading,
  EmptyList,
  Title,
  Message,
  ProductList,
  Layout,
  TgMain,
} from 'components';
import moment from 'moment';
import Button from 'components/button';

export default function TimetableDetailPage() {
  const navigate = useNavigate();
  const routeParams = useParams();

  const [productFields, setProductFields] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [categories, setCategories] = useState(null);
  const [timetable, setTimetable] = useState(null);
  const [tomorrowTimetable, setTomorrowTimetable] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const categoriesData = await CategoryApi.listCategories();
      const timetableData = await TimetableApi.getById(routeParams.id);

      setTimetable(timetableData);
      setCategories(categoriesData);
    } catch (e) {
      setError(e);
    }

    try {
      const tomorrowTimetableData = await TimetableApi.getTomorrowTimetable();
      setTomorrowTimetable(tomorrowTimetableData);
    } catch (e) {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const onProductQuantityChange = (id, count) => {
    setProductFields({ ...productFields, [id]: count });
  };

  const onContinue = () => {
    navigate('/order', {
      state: {
        productFields,
        timetable,
      },
    });
  };

  const onNextTimetable = () => {
    navigate('/tomorrow');
  };

  const renderNextTimetable = () => {
    if (!tomorrowTimetable) {
      return (
        <Layout>
          <Message text="Блюда отсутствуют." />
        </Layout>
      );
    }

    const district = tomorrowTimetable.districtTitle;
    const date = tomorrowTimetable.sheduledAtText;
    const text = `В следующий раз для жителей ${district} СКАТЕРОЧКА накроет стол ${date}.`;

    return (
      <Layout>
        <Message text="Время заказа вышло." />
        <Message text={text} />
        <Button text="Посмотреть меню" onClick={onNextTimetable} />
      </Layout>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <Loading text="Загрузка..." />;
  }

  if (error) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  if (!timetable || !timetable.menu || !timetable.menu.products || !timetable.isOrderCanBeCreated) {
    return renderNextTimetable();
  }

  const date = moment(timetable.sheduledAt).format('dddd, DD MMMM');
  const title = date.charAt(0).toUpperCase() + date.slice(1);

  return (
    <Layout>
      <TgMain text="Оформить заказ" onClick={onContinue} escaped={false} />
      <Title text={title} />
      <ProductList
        categories={categories || []}
        products={timetable.menu.products || []}
        availablities={timetable.availableQuantities}
        onQuantityChange={onProductQuantityChange}
      />
    </Layout>
  );
}
